import { Requestor } from '../../models/Requestor';
import { CustomResponse } from '../../types/CustomResponse';

export const list =
  <E = any>(requestor: Requestor) =>
  async (filters: any = {}) => {
    try {
      const { data } = await requestor.makeRequest.get<CustomResponse<E[]>>(
        `?${new URLSearchParams(filters).toString()}`
      );
      return data;
    } catch (e) {
      throw { success: false, error: e };
    }
  };
