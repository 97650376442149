import { Requestor } from '../../models/Requestor';
import { CustomResponse } from '../../types/CustomResponse';

export const create =
  <E = any>(requestor: Requestor) =>
  async (body: any) => {
    if (!requestor.validate.create(body)) {
      throw { success: false, error: 'Invalid data supplied' };
    }

    const { data } = await requestor.makeRequest.post<CustomResponse<E>>('/', body);
    return data;
  };
