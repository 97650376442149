import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getPublicUrl, useDocuments } from '../../../hooks/useDocuments/useDocuments';
import { UploadDocument } from '../../uploadDocument/UploadDocument';
import { BasicGrid } from '../BasicGrid';

const columns: ColDef[] = [
  {
    headerName: 'File',
    resizable: true,
    width: 200,
    cellRenderer: (params: any) => (
      <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img
          alt={params.data.name.es}
          style={{ height: 30, width: 30, objectFit: 'contain' }}
          src={getPublicUrl(params.data)}
        />
      </div>
    ),
  },
  {
    field: 'name',
    headerName: 'Nombre',
    resizable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data.name.es;
    },
  },
  {
    field: 'description',
    headerName: 'description',
    valueGetter: (params: ValueGetterParams) => {
      return params.data.description.es;
    },
  },
  {
    headerName: 'Tipo',
    valueGetter: (params: ValueGetterParams) => {
      return params.data.file.mimetype;
    },
  },
  {
    headerName: 'Peso',
    valueGetter: (params: ValueGetterParams) => {
      return params.data.file.size / (1024 * 1024);
    },
  },
];

export const Documents = (props: { new?: boolean; update?: boolean }) => {
  const { list: getDocuments, delete: remove } = useDocuments();
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setOpenModal(!!props.new || !!props.update);
  }, [props.new, props.update]);

  return (
    <>
      <BasicGrid
        columns={columns}
        fetchInitial={getDocuments}
        onDelete={(a: any) => remove(a._id)}
        onEdit={(a: any) => navigate(a._id)}
      ></BasicGrid>
      {openModal && (
        <UploadDocument
          open={openModal}
          setOpen={() => {
            navigate('/documents');
          }}
          onDocumentUpdate={() => {
            /* should update the list of documents */
          }}
        ></UploadDocument>
      )}
    </>
  );
};
