import { useMemo } from 'react';
import { useBackend } from '../useBackend';

export const getPublicUrl = (document: any) => `${process.env.REACT_APP_BACK_END_PUBLIC_URL}/${document.file.filename}`;

export interface Document {
  _id: string;
  file: {
    originalname: string;
    filename: string;
    encoding: string;
    mimetype: string;
    size: number;
  };
  tmp: boolean;
  updatedAt: string;
  createdAt: string;
  user: string;
  name: Record<string, string>;
  description: Record<string, string>;
}

export const useDocuments = () => {
  const { requestor, endpoints } = useBackend<Document>('documents');
  return useMemo(
    () => ({
      ...endpoints,
      getPublicUrl,
      upload: async (document: any, id?: string) => {
        const formData = new FormData();
        formData.append('document', document);
        const { data } = await requestor.makeRequest[id ? 'put' : 'post'](id ? `/upload/${id}` : '/upload', formData, {
          headers: { 'Content-type': 'multipart/form-data' },
        });
        return data;
      },
    }),
    []
  );
};
