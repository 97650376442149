import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { BasicGrid } from '../BasicGrid';
import { useBranches } from '../../../hooks/useBranches/useBranches';
import { useNavigate } from 'react-router-dom';

const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Título',
    resizable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data.name.es;
    },
  },
  {
    field: 'times',
    headerName: 'Times',
    resizable: true,
  },
  {
    field: 'phoneNumber',
    headerName: 'Teléfono',
  },
  {
    field: 'address',
    headerName: 'Dirección',
  },
];

export const Branches = () => {
  const { list: getBranches, delete: remove } = useBranches();
  const navigate = useNavigate();
  return (
    <BasicGrid
      columns={columns}
      fetchInitial={getBranches}
      onDelete={(a: any) => remove(a._id)}
      onEdit={(a: any) => navigate(a._id)}
    ></BasicGrid>
  );
};
