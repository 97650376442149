import axios from 'axios';

export const edit = async (userId: string, { id, ...user }: any) => {
  if (!user.email) {
    throw { success: false, error: 'Invalid data supplied' };
  }

  try {
    const { data } = await axios.put(`users/${userId}`, { email: user.email, name: user.name });
    return data;
  } catch (e) {
    throw { success: false, error: e };
  }
};
