import { KeyboardArrowLeftOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAlerts } from '../../contexts/Alert';
import { FormField, FormGroupFields, FormGroupRow } from '../../models/Form';
import { DocumentInput } from '../inputs/document/DocumentInput';
import { RichTextEditor } from '../richTextEditor/RichTextEditor';

export const BasicForm = ({
  formStructure,
  get,
  create,
  update,
  defaults,
}: {
  formStructure: FormGroupFields<any>;
  get: (id: string) => any;
  create: (form: any) => any;
  update: (id: string, form: any) => any;
  defaults: any;
}) => {
  const { show } = useAlerts();
  const params = useParams();
  const isEdit = !!params?.id;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState<any>(defaults);

  const refresh = () => {
    if (params.id) {
      setIsLoading(true);
      get(params.id)
        .then((parsedResult: any) => setForm(parsedResult))
        .catch((e: any) => console.log('error'))
        .finally(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  const handleChange = (field: FormField<any>) => (event: any) => {
    const value =
      field.valueGetter?.(event.currentTarget?.value ?? event.target?.value ?? event) ??
      event.currentTarget?.value ??
      event.target?.value ??
      event;
    setForm((f: any) => ({ ...f, [field.name as string]: value } as any));
  };

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (!isEdit) {
        const result = await create(form);
        show({ severity: 'success', content: `Item #${result._id} creado satisfactoriamente` });
      } else {
        await update(form._id, form);
        show({ severity: 'success', content: `Item #${form._id} actualizado satisfactoriamente` });
      }
      setTimeout(() => navigate(-1), 3000);
    } catch (e: any) {
      show({
        severity: 'error',
        content: (
          <>
            <Typography variant='body1'>
              Hubo un error mientras se creaba | actualizaba el item, inténtelo de nuevo más tarde.
            </Typography>
            <Typography variant='caption'>{e.error}</Typography>
          </>
        ) as any,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const onCancel = () => navigate(-1);

  return isLoading ? (
    <CircularProgress></CircularProgress>
  ) : (
    <Container maxWidth='md'>
      <Stack alignItems={'flex-start'}>
        <Button
          onClick={() => navigate(-1)}
          size='small'
          variant='text'
          sx={{ display: 'flex', alignItems: 'center', paddingLeft: 0 }}
          color='info'
        >
          <KeyboardArrowLeftOutlined sx={{ paddingLeft: 0 }} />
          <Typography variant='button'>Atrás</Typography>
        </Button>
        <Typography variant='h4'>{isEdit ? 'Editar' : 'Nuevo'}</Typography>
      </Stack>
      <Divider sx={{ margin: '1rem 0' }} />
      <form onSubmit={onSubmit}>
        <Stack spacing={3}>
          {formStructure.map((f: FormGroupRow<any>) => {
            const fields = Array.isArray(f) ? f : [f];
            return (
              <Box width='100%' gap={'1rem'} display='flex' flexDirection='row'>
                {fields.map((field) => {
                  return field.type === 'select' ? (
                    <FormControl fullWidth required={field.required}>
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        {...(field as any)}
                        variant='outlined'
                        value={field.valueFormatter?.(form[field.name]) ?? form[field.name]}
                        onChange={handleChange(field)}
                        disabled={isLoading}
                      >
                        {(field.options as any).map((opt: any) => {
                          return <MenuItem value={opt.value}>{opt.label}</MenuItem>;
                        })}
                      </Select>
                    </FormControl>
                  ) : field.type === 'html' ? (
                    <RichTextEditor initialValue={form[field.name]} onChange={handleChange(field)} />
                  ) : field.type === 'document' ? (
                    <DocumentInput
                      {...field}
                      variant='outlined'
                      value={form[field.name]}
                      onChange={handleChange(field)}
                    />
                  ) : (
                    <TextField
                      {...field}
                      variant='outlined'
                      fullWidth
                      disabled={isLoading}
                      value={field.valueFormatter?.(form[field.name]) ?? form[field.name]}
                      onChange={handleChange(field)}
                    />
                  );
                })}
              </Box>
            );
          })}
          <Box display='flex' gap='1rem' justifyContent={'flex-end'}>
            <Button color='inherit' variant='outlined' onClick={onCancel} disabled={isLoading}>
              {isEdit ? 'Volver' : 'Cancelar'}
            </Button>
            <Button type='submit' color='primary' variant='contained' disabled={isLoading}>
              {isEdit ? 'Guardar ' : 'Crear '} Item
            </Button>
          </Box>
        </Stack>
      </form>
    </Container>
  );
};
