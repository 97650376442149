import axios from 'axios';

export const get = async (userId: string) => {
  try {
    const { data } = await axios.get(`users/${userId}`);
    return data;
  } catch (e) {
    throw { success: false, error: e };
  }
};
