import { Requestor } from '../../models/Requestor';
import { CustomResponse } from '../../types/CustomResponse';

export const get =
  <E = any>(requestor: Requestor) =>
  async (id: string) => {
    try {
      const { data } = await requestor.makeRequest.get<CustomResponse<E>>(id);
      return data;
    } catch (e) {
      throw { success: false, error: e };
    }
  };
