import { AddAPhotoOutlined } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useAlerts } from '../../contexts/Alert';
import { Document, useDocuments } from '../../hooks/useDocuments/useDocuments';
import { UploadDocument } from '../uploadDocument/UploadDocument';

interface DocumentPickerProps {
  initialSelection?: Document;
  onClose: () => void;
  onDocumentSelected: (document: Document) => void | Promise<void>;
}

export const DocumentPicker = ({ initialSelection, onClose, onDocumentSelected }: DocumentPickerProps) => {
  const [isUploadDocumentOpen, setIsUploadDocumentOpen] = useState(false);
  const [loadingDocuments, setLoadingDocuments] = useState(false);
  const { list: listDocuments, getPublicUrl } = useDocuments();
  const [documents, setDocuments] = useState<Document[]>([]);
  const handleClose = () => onClose();
  const alert = useAlerts();
  const [selected, setSelected] = useState(initialSelection);

  const refreshDocuments = () => {
    setLoadingDocuments(true);
    listDocuments()
      .then((response) => setDocuments(response.data))
      .catch((e) => alert.show({ severity: 'error', content: 'Error when reading documents' }))
      .finally(() => setLoadingDocuments(false));
  };

  const onSelect = () => {
    if(selected) {
      onDocumentSelected(selected);
      onClose();
    }
  };

  useEffect(() => refreshDocuments(), []);

  return (
    <>
      <Dialog open={true} onClose={handleClose} maxWidth='md' fullWidth>
        {loadingDocuments ? (
          <CircularProgress />
        ) : (
          <>
            <DialogTitle>
              <Stack direction='row' alignItems='center'>
                <Typography variant='h5' sx={{ flexGrow: 1 }}>
                  Seleccione Imagen
                </Typography>
                <Stack alignSelf={'flex-end'}>
                  <IconButton onClick={() => setIsUploadDocumentOpen(true)}>
                    <AddAPhotoOutlined />
                  </IconButton>
                </Stack>
              </Stack>
            </DialogTitle>
            <DialogContent>
              <ImageList sx={{ width: '100%', height: 450 }} cols={4} rowHeight={164}>
                {documents.map((item: any) => (
                  <ImageListItem
                    key={item._id}
                    sx={{ boxShadow: selected?._id === item._id ? '0 0 5px 5px #6666' : 'none' }}
                    onClick={() => setSelected(item)}
                  >
                    <img
                      src={`${getPublicUrl(item)}`}
                      alt={`${item.name?.es} - ${item.description?.es}`}
                      style={{ height: 164, width: '100%', objectFit: 'cover' }}
                    />
                    <ImageListItemBar title={item.name?.es} subtitle={item.description?.es} />
                  </ImageListItem>
                ))}
              </ImageList>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancelar</Button>
              <Button color='primary' onClick={onSelect} disabled={!selected}>
                Subir
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      {isUploadDocumentOpen && (
        <UploadDocument
          open={isUploadDocumentOpen}
          setOpen={() => setIsUploadDocumentOpen(false)}
          onDocumentUpdate={refreshDocuments}
        />
      )}
    </>
  );
};
