import axios from 'axios';

export const list = async () => {
  try {
    const { data } = await axios.get('users');
    return data;
  } catch (e) {
    throw { success: false, error: e };
  }
};
