import { BrowserRouter, Routes as ReactRoutes, Route } from 'react-router-dom';
import { Admin } from './components/admin/Admin';
import { Dashboard } from './components/admin/Dashboard';
import { Login } from './components/login/Login';
import { ProtectRoute } from './components/protectRoute/ProtectRoute';
import { Signup } from './components/signup/SignUp';
import { Users } from './components/admin/users/Users';
import { CreateOrUpdateUser } from './components/admin/users/CreateOrUpateUser';
import { CreateOrUpdateCategory } from './components/admin/categories/CreateOrUpdateCategory';
import { Categories } from './components/admin/categories/Categories';
import { CreateOrUpdateArticle } from './components/admin/articles/CreateOrUpdateArticle';
import { Articles } from './components/admin/articles/Articles';
import { Documents } from './components/admin/documents/Documents';
import { CreateOrUpdateBranch } from './components/admin/branches/CreateOrUpdateBranch';
import { Branches } from './components/admin/branches/Branches';
import { CreateOrUpdateExternalBenefits } from './components/admin/externalBenefits/CreateOrUpdateExternalBenefits';
import { ExternalBenefits } from './components/admin/externalBenefits/ExternalBenefits';

const basename = process.env.REACT_APP_BASENAME ?? '/';

export const Routes = () => {
  return (
    <BrowserRouter basename={basename}>
      <ReactRoutes>
        <Route path='login' element={<Login />} />
        <Route path='signup' element={<Signup />} />
        <Route path='/' element={ProtectRoute(<Admin />)}>
          <Route path='categories'>
            <Route path='new' element={<CreateOrUpdateCategory />} />
            <Route path=':id' element={<CreateOrUpdateCategory />} />
            <Route index element={<Categories />} />
          </Route>
          <Route path='articles'>
            <Route path='new' element={<CreateOrUpdateArticle />} />
            <Route path=':id' element={<CreateOrUpdateArticle />} />
            <Route index element={<Articles />} />
          </Route>
          <Route path='documents'>
            <Route path='new' element={<Documents new />} />
            <Route path=':id' element={<Documents update />} />
            <Route index element={<Documents />} />
          </Route>
          <Route path='users'>
            <Route path='new' element={<CreateOrUpdateUser />} />
            <Route path=':id' element={<CreateOrUpdateUser />} />
            <Route index element={<Users />} />
          </Route>
          <Route path='branches'>
            <Route path='new' element={<CreateOrUpdateBranch />} />
            <Route path=':id' element={<CreateOrUpdateBranch />} />
            <Route index element={<Branches />} />
          </Route>
          <Route path='external-benefits'>
            <Route path='new' element={<CreateOrUpdateExternalBenefits />} />
            <Route path=':id' element={<CreateOrUpdateExternalBenefits />} />
            <Route index element={<ExternalBenefits />} />
          </Route>
          <Route index element={ProtectRoute(<Dashboard />)} />
        </Route>
      </ReactRoutes>
    </BrowserRouter>
  );
};
