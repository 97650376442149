import styled from '@emotion/styled';
import { Alert, AlertProps, AlertTitle } from '@mui/material';
import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import Chance from 'chance';

type CloseAlertFunction = (alertId: string) => void;
type WrappedCloseAlertFunction = (alertId?: string) => CloseAlertFunction;
type ShowAlertFunction = (alert: AlertType) => CloseAlertFunction;
const AlertContext = createContext<{ show: ShowAlertFunction; close: CloseAlertFunction }>({} as any);

export const useAlerts = () => {
  return useContext(AlertContext);
};

const DEFAULT_ALERT_DURATION = 3000;

type AlertType = AlertProps & { title?: string; content: any };
type CreatedAlert = AlertType & { id: number; closeFn: () => void };

const AlertContainer = styled.div`
  position: fixed;
  height: 100px;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 100000;
`;

const chance = new Chance();

export const AlertContextProvider: React.FunctionComponent<{ duration?: number; children: ReactNode }> = ({
  duration = DEFAULT_ALERT_DURATION,
  children,
}) => {
  const [alerts, setAlerts] = useState<CreatedAlert[]>([]);

  const closeFactory: WrappedCloseAlertFunction = (defaultAlertId?: string) => (alertId: string) =>
    setAlerts((a: CreatedAlert[]) => a.filter((x: CreatedAlert) => x.id !== (alertId ?? defaultAlertId)));

  const show: ShowAlertFunction = (alert: AlertProps) => {
    const alertId = chance.hash({ length: 15 });
    const closeFn = closeFactory(alertId);
    setTimeout(closeFn, duration);
    setAlerts((a) => [...a, { ...alert, id: alertId, closeFn }] as CreatedAlert[]);
    return closeFn;
  };

  return (
    <AlertContext.Provider value={useMemo(() => ({ close: closeFactory(), show }), [alerts, setAlerts])}>
      {children}
      {alerts.length ? (
        <AlertContainer>
          {alerts.map((a) => (
            <Alert {...a} onClose={() => a.closeFn()}>
              {a.title ? <AlertTitle>{a.title}</AlertTitle> : <></>}
              {a.content}
            </Alert>
          ))}
        </AlertContainer>
      ) : (
        <></>
      )}
    </AlertContext.Provider>
  );
};
