import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAlerts } from '../../contexts/Alert';
import { Document, useDocuments } from '../../hooks/useDocuments/useDocuments';

export const UploadDocument = ({
  open,
  setOpen,
  onDocumentUpdate,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  onDocumentUpdate: () => void | Promise<void>;
}) => {
  const { get, upload, create, getPublicUrl, edit } = useDocuments();
  const params = useParams();
  const handleClose = () => setOpen(false);
  const alert = useAlerts();
  const [preview, setPreview] = useState('');
  const [createForm, setCreateForm] = useState<Partial<Document>>({
    file: undefined,
    _id: undefined,
    name: { es: '' },
    description: { es: '' },
  });

  useEffect(() => {
    if (params.documentId) {
      get(params.documentId).then((response) => {
        const {
          data: { file, ...document },
        } = response;
        setCreateForm(document);
        setPreview(getPublicUrl({ file }));
      });
    }
  }, [params.documentId]);

  const handleFileChange = async (e: any) => {
    if (e.currentTarget.files[0]) {
      if (e.currentTarget.files[0]) {
        setPreview(URL.createObjectURL(e.currentTarget.files[0]));
      } else {
        setPreview('');
      }
      setCreateForm({ ...createForm, file: e.currentTarget.files[0] });
    } else {
      setCreateForm({ ...createForm, file: undefined });
    }
  };

  const handleCreateDocument = async () => {
    const { file, ...form } = createForm;
    let uploadResponse;
    if (file) {
      uploadResponse = await upload(file, params.documentId);
    }
    if (!params.documentId) {
      await create({ ...form, _id: uploadResponse.data._id });
      handleClose();
      setPreview('');
      alert.show({ severity: 'success', content: 'Creaste el documento exitosamente' });
    } else {
      await edit(params.documentId as string, form);
      alert.show({ severity: 'success', content: 'Actualizaste el documento exitosamente' });
    }
    onDocumentUpdate();
  };

  return (
    <>
      <Dialog
        open={open}
        maxWidth='md'
        fullWidth
        onClose={() => {
          handleClose();
          setPreview('');
        }}
      >
        <DialogTitle>
          <Stack direction='row' alignItems='center'>
            <Typography variant='h5' sx={{ flexGrow: 1 }}>
              {params.documentId ? 'Actualizar' : 'Nuevo'} documento
            </Typography>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack direction='row' gap='1rem'>
            <Stack flexGrow={1} sx={{ width: '100%' }}>
              {preview && (
                <img
                  src={preview}
                  alt='demo'
                  style={{
                    width: '100%',
                    height: '300px',
                    objectPosition: 'center',
                    objectFit: 'contain',
                    backgroundColor: '#dadada',
                  }}
                ></img>
              )}
              <DialogContentText>Seleccione documento acargar</DialogContentText>
              <TextField
                autoFocus
                margin='dense'
                id='document'
                label='Documento'
                type='file'
                fullWidth
                variant='standard'
                onChange={handleFileChange}
              />
            </Stack>
            <Stack flexGrow={1} sx={{ width: '100%' }}>
              <TextField
                autoFocus
                margin='dense'
                id='name'
                label='Nombre'
                type='text'
                fullWidth
                variant='standard'
                onChange={(e) => setCreateForm({ ...createForm, name: { es: e.currentTarget.value } })}
                value={createForm.name?.es}
              />
              <TextField
                autoFocus
                margin='dense'
                id='description'
                label='Descripción'
                type='text'
                fullWidth
                variant='standard'
                onChange={(e) => setCreateForm({ ...createForm, description: { es: e.currentTarget.value } })}
                value={createForm.description?.es}
              />
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button
            color='primary'
            onClick={handleCreateDocument}
            disabled={!createForm.description?.es || !createForm.name?.es || !createForm.file}
          >
            {params.documentId ? 'Actualizar' : 'Subir'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
