import { deserialize, serialize } from "./functions";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';

type RichTextEditorProps = {
  initialValue: string;
  onChange: (newText: string) => void;
  readOnly?: boolean;
};

export const RichTextEditor = ({ initialValue, onChange, readOnly }: RichTextEditorProps) => {

  const handleChange = (content: any) => {
    onChange(serialize(content));
  };

  return <SunEditor
    defaultValue={deserialize(initialValue)}
    onChange={handleChange}
    height="500"
    setOptions={{
      buttonList: [
        ["undo", "redo"],
        ["font", "fontSize", "formatBlock", "fontColor", "removeFormat"],
        ["bold", "underline", "italic", "strike", "subscript", "superscript"],
        ["outdent", "indent"],
        ["link", "image"],
        ["align", "horizontalRule", "list", "table"],
        ["preview", "print"],
        ["showBlocks", "codeView"]
      ]
    }}
  />;  
};