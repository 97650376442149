import { Node } from 'slate';
import { slateToHtml } from '@slate-serializers/html';

export const serialize = (value: Node[]) => {
  return JSON.stringify(value);
};

export const deserialize = (content: string) => {
  try {
    const deserializedContent = JSON.parse(content);
    if(Array.isArray(deserializedContent)) return slateToHtml(deserializedContent);
    return deserializedContent;
  } catch (e) {
    return content;
  }
};
