import { useMemo } from 'react';
import { create } from './create';
import { list } from './list';
import { get } from './get';
import { edit } from './edit';
import { Requestor } from '../../models/Requestor';
import { remove } from './remove';

export const useBackend = <E = any>(path: string) => {
  const requestor = new Requestor(path);
  return useMemo(
    () => ({
      requestor,
      endpoints: {
        create: create<E>(requestor),
        edit: edit<E>(requestor),
        list: list<E>(requestor),
        get: get<E>(requestor),
        delete: remove<E>(requestor),
      },
    }),
    []
  );
};
