import { createElement } from 'react';
import { Button, Stack } from '@mui/material';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AddOutlined, DeleteOutline, EditOutlined } from '@mui/icons-material';
import { useAlerts } from '../../contexts/Alert';
import { CustomResponse } from '../../types/CustomResponse';

const colDefault: Partial<ColDef> = {
  resizable: true,
  sortable: true,
};

type GridRowCallback = (rowData: any) => Promise<CustomResponse<any>> | Promise<void> | void;

type GridAction = 'edit' | 'delete';

const actionIconRender = (action: GridAction) => (action === 'edit' ? EditOutlined : DeleteOutline);

const actionColumnRenderer = (action: GridAction) => (props: ICellRendererParams) => {
  const {
    node: { group: isGroup },
    data,
    context,
  } = props;
  const button = createElement(actionIconRender(action), {
    style: { cursor: 'pointer' },
    onClick: () => context[action](data),
  });
  return isGroup || !context[action] ? (
    <></>
  ) : (
    <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      {button}
    </div>
  );
};

const actionsCol: Record<GridAction, ColDef> = {
  edit: {
    width: 48,
    cellRenderer: actionColumnRenderer('edit'),
  },
  delete: {
    width: 48,
    cellRenderer: actionColumnRenderer('delete'),
  },
};

export const BasicGrid = ({
  fetchInitial,
  columns,
  onDelete,
  onEdit,
}: {
  fetchInitial: () => any;
  columns: ColDef[];
  onDelete?: GridRowCallback;
  onEdit?: GridRowCallback;
}) => {
  const { show: showAlert } = useAlerts();
  const [data, setData] = useState<any[]>([]);
  const gridRef = useRef<AgGridReact>();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      gridRef.current?.api?.showLoadingOverlay();
    } else {
      gridRef.current?.columnApi?.applyColumnState({ state: [{ colId: 'createdAt', sort: 'desc' }] });
      gridRef.current?.api?.hideOverlay();
    }
  }, [isLoading, gridRef.current]);

  const refresh = useCallback(() => {
    Promise.all([fetchInitial()])
      .then(([{ data: results }]) => {
        setData(results);
      })
      .catch((e) => {
        showAlert({ severity: 'error', content: 'Hubo un error al listar los elementos' });
      })
      .finally(() => setIsLoading(false));
  }, [fetchInitial, setData, setIsLoading]);

  useEffect(() => {
    refresh();
  }, []);

  return (
    <Stack spacing={2} sx={{ height: '100%' }}>
      <Stack direction='row' justifyContent='space-between' alignItems='center'>
        <Button onClick={() => navigate('new')} startIcon={<AddOutlined />} color='primary' sx={{ marginLeft: 'auto' }}>
          Agregar Item
        </Button>
      </Stack>
      <article id='myGrid' className='ag-theme-alpine' style={{ height: '100%' }}>
        <AgGridReact
          ref={gridRef as any}
          rowData={data}
          context={{
            delete: onDelete
              ? async (rowData: any) => {
                  await onDelete?.(rowData);
                  refresh();
                }
              : null,
            edit: onEdit
              ? async (rowData: any) => {
                  await onEdit?.(rowData);
                }
              : null,
          }}
          columnDefs={[...columns.map((d) => ({ ...colDefault, ...d }))].concat(
            ...([] as ColDef[])
              .concat(!!onEdit ? actionsCol.edit : [])
              .concat(!!onDelete ? actionsCol.delete : [])
              .filter((x) => !!x)
          )}
          onRowDoubleClicked={(r) => {
            navigate(r.data._id);
          }}
        />
      </article>
    </Stack>
  );
};
