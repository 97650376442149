import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {
  Avatar,
  Breadcrumbs,
  Collapse,
  Drawer,
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import {
  AccountBox,
  AccountCircle,
  Add,
  DiscountOutlined,
  ExpandLess,
  ExpandMore,
  FileUploadOutlined,
  FormatListBulleted,
  Home,
  Logout,
  NavigateNext,
  StoreMallDirectoryOutlined,
  TextSnippet,
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const PAGE_NAME_BY_LOCATION: Record<string, string> = {
  new: 'Nuevo',
  articles: 'Artículos',
  categories: 'Categorías',
  users: 'Usuarios',
  admin: 'Home',
  documents: 'Multimedia',
  branches: 'Sucursales',
  'external-benefits': 'Descuentos Externos',
};

export const Header = () => {
  const location = useLocation();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const pathnames = location.pathname.split('/').filter((x) => x);

  useEffect(() => {
    setDrawerOpen(false);
  }, [location]);

  const toggleDrawer = (open: boolean) => (event: any) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setDrawerOpen(open);
  };

  return (
    <>
      <AppBar position='sticky'>
        <Toolbar>
          <IconButton
            size='large'
            edge='start'
            color='inherit'
            aria-label='menu'
            sx={{ mr: 2 }}
            onClick={toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Breadcrumbs
            separator={<NavigateNext fontSize='small' color='inherit' />}
            aria-label='breadcrumb'
            sx={{ flexGrow: 1 }}
          >
            {pathnames.map((value, index) => {
              const last = index === pathnames.length - 1;
              const to = `/${pathnames.slice(0, index + 1).join('/')}`;
              return last ? (
                <Typography key={to} color='white'>
                  {PAGE_NAME_BY_LOCATION[value]}
                </Typography>
              ) : (
                <Link href={to} key={to} color='white' underline='hover'>
                  {PAGE_NAME_BY_LOCATION[value]}
                </Link>
              );
            })}
          </Breadcrumbs>
          <AccountMenu />
        </Toolbar>
      </AppBar>
      <Drawer anchor='left' open={drawerOpen} onClose={toggleDrawer(false)} BackdropProps={{}}>
        <PageSidebar />
      </Drawer>
    </>
  );
};

export const AccountMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { logout, email } = useAuth();

  const handleMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Stack direction='row' alignItems='center'>
        <IconButton
          size='large'
          aria-label='account of current user'
          aria-controls='menu-appbar'
          aria-haspopup='true'
          onClick={handleMenu}
          color='inherit'
        >
          <Avatar sx={{ width: 32, height: 32 }}>{email?.[0]?.toUpperCase()}</Avatar>
        </IconButton>
      </Stack>
      <Menu
        id='menu-appbar'
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          Profile
        </MenuItem>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Cerrar Sesión
        </MenuItem>
      </Menu>
    </div>
  );
};

export const PageSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [expanded, setExpanded] = useState(new Set());

  const goTo = (page: string) => () => navigate(`/${page}`);
  const toggleExpand = (item: string) =>
    setExpanded(
      (currentState) => (
        currentState.has(item) ? currentState.delete(item) : currentState.add(item), new Set(currentState)
      )
    );

  return (
    <Paper sx={{ width: 320, maxWidth: '100%' }} elevation={0}>
      <MenuList>
        <MenuItem onClick={goTo('')} color={location.pathname === '/' ? 'primary' : 'default'}>
          <ListItemIcon>
            <Home />
          </ListItemIcon>
          <ListItemText>Admin</ListItemText>
        </MenuItem>
        <MenuList>
          <MenuItem
            onClick={() => {
              goTo('articles');
              toggleExpand('articles');
            }}
            color={location.pathname === '/articles' ? 'primary' : 'default'}
          >
            <ListItemIcon>
              <TextSnippet />
            </ListItemIcon>
            <ListItemText>Artículos</ListItemText>
            {expanded.has('articles') ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={expanded.has('articles')} timeout='auto' unmountOnExit>
            <MenuItem onClick={goTo('articles')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <TextSnippet />
              </ListItemIcon>
              <ListItemText primary='Ver todos' />
            </MenuItem>
            <MenuItem onClick={goTo('articles/new')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary='Nuevo artículo' />
            </MenuItem>
          </Collapse>

          <MenuItem
            onClick={() => {
              goTo('categories');
              toggleExpand('categories');
            }}
            color={location.pathname === '/categories' ? 'primary' : 'default'}
          >
            <ListItemIcon>
              <FormatListBulleted />
            </ListItemIcon>
            <ListItemText>Categorías</ListItemText>
            {expanded.has('categories') ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={expanded.has('categories')} timeout='auto' unmountOnExit>
            <MenuItem onClick={goTo('categories')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <FormatListBulleted />
              </ListItemIcon>
              <ListItemText primary='Ver todos' />
            </MenuItem>
            <MenuItem onClick={goTo('categories/new')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary='Nueva categoría' />
            </MenuItem>
          </Collapse>

          <MenuItem
            onClick={() => {
              goTo('branches');
              toggleExpand('branches');
            }}
            color={location.pathname === '/branches' ? 'primary' : 'default'}
          >
            <ListItemIcon>
              <StoreMallDirectoryOutlined />
            </ListItemIcon>
            <ListItemText>Sucursales</ListItemText>
            {expanded.has('branches') ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={expanded.has('branches')} timeout='auto' unmountOnExit>
            <MenuItem onClick={goTo('branches')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <FormatListBulleted />
              </ListItemIcon>
              <ListItemText primary='Ver todas' />
            </MenuItem>
            <MenuItem onClick={goTo('brances/new')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary='Nueva Sucursal' />
            </MenuItem>
          </Collapse>

          <MenuItem
            onClick={() => {
              goTo('external-benefits');
              toggleExpand('external-benefits');
            }}
            color={location.pathname === '/external-benefits' ? 'primary' : 'default'}
          >
            <ListItemIcon>
              <DiscountOutlined />
            </ListItemIcon>
            <ListItemText>Descuentos</ListItemText>
            {expanded.has('external-benefits') ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={expanded.has('external-benefits')} timeout='auto' unmountOnExit>
            <MenuItem onClick={goTo('external-benefits')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <FormatListBulleted />
              </ListItemIcon>
              <ListItemText primary='Ver todos' />
            </MenuItem>
            <MenuItem onClick={goTo('external-benefits/new')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary='Nuevo Descuento' />
            </MenuItem>
          </Collapse>

          <MenuItem
            onClick={() => {
              goTo('documents');
              toggleExpand('documents');
            }}
            color={location.pathname === '/documents' ? 'primary' : 'default'}
          >
            <ListItemIcon>
              <FileUploadOutlined />
            </ListItemIcon>
            <ListItemText>Multimedia</ListItemText>
            {expanded.has('documents') ? <ExpandLess /> : <ExpandMore />}
          </MenuItem>
          <Collapse in={expanded.has('documents')} timeout='auto' unmountOnExit>
            <MenuItem onClick={goTo('documents')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <FormatListBulleted />
              </ListItemIcon>
              <ListItemText primary='Ver todos' />
            </MenuItem>
            <MenuItem onClick={goTo('documents/new')} sx={{ pl: 4 }}>
              <ListItemIcon>
                <Add />
              </ListItemIcon>
              <ListItemText primary='Nuevo documento' />
            </MenuItem>
          </Collapse>

          <MenuItem onClick={goTo('users')} color={location.pathname === '/users' ? 'primary' : 'default'}>
            <ListItemIcon>
              <AccountBox />
            </ListItemIcon>
            <ListItemText>Usuarios</ListItemText>
          </MenuItem>
        </MenuList>
      </MenuList>
    </Paper>
  );
};
