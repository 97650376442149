import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useArticles } from '../../../hooks/useArticles/useArticles';
import { BasicGrid } from '../BasicGrid';
import { useNavigate } from 'react-router-dom';

const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Título',
    resizable: true,
    width: 500,
    valueGetter: (params: ValueGetterParams) => {
      return params.data.name.es;
    },
  },
  {
    field: 'intro',
    headerName: 'Introducción',
    resizable: true,
    width: 300,
    valueGetter: (params: ValueGetterParams) => {
      return params.data.intro.es;
    },
  },
  {
    field: 'state',
    headerName: 'Publicado',
    resizable: true,
    valueFormatter: (params: ValueFormatterParams) => {
      return params.value === 'PUBLISHED' ? 'Sí' : 'No';
    },
  },
  {
    field: 'firstPublishDate',
    headerName: 'Fecha de publicación',
    resizable: true,
    valueGetter: (params: ValueGetterParams) => {
      const value = params.data.firstPublishDate;
      return !value ? '-' : new Date(value);
    },
  },
];

export const Articles = () => {
  const { list: getArticles, delete: remove } = useArticles();
  const navigate = useNavigate();
  return (
    <BasicGrid
      columns={columns}
      fetchInitial={getArticles}
      onDelete={(a: any) => remove(a._id)}
      onEdit={(a: any) => navigate(a._id)}
    ></BasicGrid>
  );
};
