import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCategories } from '../../../hooks/useCategories/useCategories';
import { FormGroupFields } from '../../../models/Form';
import { BasicForm } from '../BasicForm';

const initialOptions = [{ value: 'none', label: 'Sin padre' }];

const formStructure: FormGroupFields<any> = [
  {
    name: 'parent',
    label: 'Dentro de categoría',
    type: 'select',
    required: true,
  },
  {
    name: 'name',
    label: 'Nombre',
    required: true,
    type: 'text',
    valueFormatter: (name: Record<string, string>) => name.es,
  },
  {
    name: 'showMainSite',
    label: 'Mostrar en página principal',
    type: 'select',
    options: [
      { value: true, label: 'Sí' },
      { value: false, label: 'No' },
    ],
  },
];

export const CreateOrUpdateCategory = () => {
  const { list: getCategories, get, create, edit: update } = useCategories();
  const [optionsReady, setOptionsReady] = useState(false);

  useEffect(() => {
    getCategories().then(({ data: allCategories }) => {
      (formStructure[0] as any).options = [
        ...initialOptions,
        ...allCategories.map((ac: any) => ({ value: ac._id, label: ac.name.es })),
      ];
      setOptionsReady(true);
    });
  }, []);

  return optionsReady ? (
    <BasicForm
      formStructure={formStructure}
      defaults={{
        name: '',
        showMainSite: false,
        parent: 'none',
      }}
      get={(id) => get(id).then((r) => r.data)}
      create={(payload) =>
        create({ ...payload, parent: payload.parent === 'none' ? null : payload.parent, name: { es: payload.name } })
      }
      update={(id, payload) =>
        update(id, {
          ...payload,
          parent: payload.parent === 'none' ? null : payload.parent,
          name: { es: payload.name },
        })
      }
    ></BasicForm>
  ) : (
    <Box sx={{ height: '100%', witdh: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress></CircularProgress>
    </Box>
  );
};
