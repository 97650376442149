import { ColDef, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { useCategories } from '../../../hooks/useCategories/useCategories';
import { BasicGrid } from '../BasicGrid';
import { useNavigate } from 'react-router-dom';

const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Nombre',
    resizable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data.name.es;
    },
  },
  {
    field: 'parentTree',
    headerName: 'Jerarquía',
    valueGetter: (params: ValueGetterParams) => {
      return params.data.parentTree
        ?.sort((a: any, b: any) => b.level - a.level)
        .map((p: any) => p.name.es)
        .join(' / ');
    },
  },
  {
    field: 'showMainSite',
    headerName: 'Mostrar en página principal',
    valueFormatter: (params: ValueFormatterParams) => {
      return params.value ? 'Sí' : 'No';
    },
  },
];

export const Categories = () => {
  const { list: getCategories, delete: remove } = useCategories();
  const navigate = useNavigate();
  return (
    <BasicGrid
      columns={columns}
      fetchInitial={getCategories}
      onDelete={(a: any) => remove(a._id)}
      onEdit={(a: any) => navigate(a._id)}
    ></BasicGrid>
  );
};
