import { UploadFileOutlined } from '@mui/icons-material';
import { FormControl, InputAdornment, InputLabel, InputProps, OutlinedInput } from '@mui/material';
import { useEffect, useState } from 'react';
import { DocumentPicker } from '../../documentPicker/DocumentPicker';
import { FormField } from '../../../models/Form';
import { Document } from '../../../hooks/useDocuments/useDocuments';

export const DocumentInput = ({
  label,
  onChange,
  value,
  ...inputProps
}: FormField & {
  onChange: any;
}) => {
  const [showUploadDocument, setShowUploadDocument] = useState(false);
  return (
    <>
      {showUploadDocument && (
        <DocumentPicker
          initialSelection={value as Document}
          onClose={() => setShowUploadDocument(false)}
          onDocumentSelected={(document) => onChange(document)}
        />
      )}
      <FormControl fullWidth sx={{ m: 0 }} onClick={() => setShowUploadDocument(true)}>
        <InputLabel htmlFor='outlined-adornment-amount'>{label ?? 'Subir archivo'}</InputLabel>
        <OutlinedInput
          {...(inputProps as InputProps)}
          type='text'
          value={(value as Document)?.file?.originalname ?? ''}
          readOnly={true}
          endAdornment={
            <InputAdornment position='end'>
              <UploadFileOutlined />
            </InputAdornment>
          }
        />
      </FormControl>
    </>
  );
};
