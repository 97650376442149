import { Requestor } from '../../models/Requestor';
import { CustomResponse } from '../../types/CustomResponse';

export const edit =
  <E = any>(requestor: Requestor) =>
  async (id: string, body: any) => {
    if (!requestor.validate.edit(body)) {
      throw { success: false, error: 'Invalid data supplied' };
    }

    try {
      const { data } = await requestor.makeRequest.put<CustomResponse<E>>(id, body);
      return data;
    } catch (e) {
      throw { success: false, error: e };
    }
  };
