import { FormGroupFields } from '../../../models/Form';
import { BasicForm } from '../BasicForm';
import { useBranches } from '../../../hooks/useBranches/useBranches';

const formStructure: FormGroupFields<any> = [
  {
    name: 'name',
    label: 'Nombre',
    required: true,
    type: 'text',
  },
  {
    name: 'img',
    label: 'Imagen',
    type: 'document',
  },
  {
    name: 'times',
    label: 'Horarios de atención',
    type: 'text',
  },
  {
    name: 'phoneNumber',
    label: 'Teléfono',
    type: 'text',
  },
  {
    name: 'address',
    label: 'Dirección',
    type: 'text',
  },
];

export const CreateOrUpdateBranch = () => {
  const { get, create, edit: update } = useBranches();

  return (
    <BasicForm
      formStructure={formStructure}
      defaults={{
        name: '',
        address: '',
        phoneNumber: '',
        times: '',
      }}
      get={(id) =>
        get(id).then((r) => ({
          ...r.data,
          name: r.data.name.es,
        }))
      }
      create={(payload) =>
        create({
          ...payload,
          name: { es: payload.name },
        })
      }
      update={(id, payload) =>
        update(id, {
          ...payload,
          name: { es: payload.name },
        })
      }
    ></BasicForm>
  );
};
