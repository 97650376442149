import { ColDef, ValueFormatterParams } from 'ag-grid-community';
import { useUsers } from '../../../hooks/useUsers';
import { BasicGrid } from '../BasicGrid';

const columns: ColDef[] = [
  {
    field: 'email',
    headerName: 'Email',
    resizable: true,
  },
  {
    field: 'name',
    headerName: 'Nombre',
    resizable: true,
  },
  {
    field: 'lastname',
    headerName: 'Apellido',
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de registro',
    valueFormatter: (r: ValueFormatterParams) => r.value.toUpperCase(),
  },
  {
    field: 'lastLoginAt',
    headerName: 'Último login',
  },
];

export const Users = () => {
  const { list: getUsers } = useUsers();
  return <BasicGrid fetchInitial={getUsers} columns={columns}></BasicGrid>;
};
