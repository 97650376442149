import { ColDef, ValueGetterParams } from 'ag-grid-community';
import { BasicGrid } from '../BasicGrid';
import { useExternalBenefits } from '../../../hooks/useExternalBenefits/useExternalBenefits';
import { useNavigate } from 'react-router-dom';

const columns: ColDef[] = [
  {
    field: 'name',
    headerName: 'Nombre',
    resizable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data.name.es;
    },
  },
  {
    field: 'description',
    headerName: 'Descripción',
    resizable: true,
    valueGetter: (params: ValueGetterParams) => {
      return params.data.description.es;
    },
  },
  {
    field: 'category',
    headerName: 'Categoría',
    valueGetter: (params: ValueGetterParams) => {
      return params.data.name.es;
    },
  },
  {
    field: 'location',
    headerName: 'Ubicación',
  },
];

export const ExternalBenefits = () => {
  const { list: getExternalBenefits, delete: remove } = useExternalBenefits();
  const navigate = useNavigate();
  return (
    <BasicGrid
      columns={columns}
      fetchInitial={getExternalBenefits}
      onDelete={(a: any) => remove(a._id)}
      onEdit={(a: any) => navigate(a._id)}
    ></BasicGrid>
  );
};
