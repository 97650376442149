import { useMemo } from 'react';
import { create } from './create';
import { list } from './list';
import { get } from './get';
import { edit } from './edit';

export const useUsers = () => {
  return useMemo(
    () => ({
      create,
      edit,
      list,
      get,
    }),
    []
  );
};
