import { Box, CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import { useArticles } from '../../../hooks/useArticles/useArticles';
import { useCategories } from '../../../hooks/useCategories/useCategories';
import { FormGroupFields } from '../../../models/Form';
import { BasicForm } from '../BasicForm';

const initialCategories: any[] = [];

const formStructure: FormGroupFields<any> = [
  [
    {
      name: 'category',
      label: 'Categoría',
      type: 'select',
      required: true,
    },
    {
      name: 'state',
      label: 'Publicado',
      type: 'select',
      options: [
        { value: 'PUBLISHED', label: 'Sí' },
        { value: 'DRAFT', label: 'No' },
      ],
    },
  ],
  {
    name: 'name',
    label: 'Título',
    required: true,
    type: 'text',
  },
  {
    name: 'mainImage',
    label: 'Imagen principal',
    type: 'document',
  },
  {
    name: 'portrait',
    label: 'Imagen de miniatura',
    type: 'document',
  },
  {
    name: 'intro',
    label: 'Introducción',
    type: 'textarea',
  },
  {
    name: 'text',
    label: 'Descripción',
    type: 'html',
  },
];

export const CreateOrUpdateArticle = () => {
  const { get, create, edit: update } = useArticles();
  const { list: getCategories } = useCategories();
  const [optionsReady, setOptionsReady] = useState(false);

  useEffect(() => {
    getCategories().then(({ data: allCategories }) => {
      ((formStructure[0] as any)[0] as any).options = [
        ...initialCategories,
        ...allCategories.map((ac: any) => ({ value: ac._id, label: ac.name.es })),
      ];
      setOptionsReady(true);
    });
  }, []);

  return optionsReady ? (
    <BasicForm
      formStructure={formStructure}
      defaults={{
        name: '',
        state: 'DRAFT',
        intro: '',
        text: '',
      }}
      get={(id) =>
        get(id).then((r) => ({
          ...r.data,
          category: r.data.category._id,
          name: r.data.name.es,
          intro: r.data.intro.es,
          text: r.data.text.es,
        }))
      }
      create={(payload) =>
        create({
          ...payload,
          name: { es: payload.name },
          intro: { es: payload.intro },
          text: { es: payload.text },
        })
      }
      update={(id, payload) =>
        update(id, {
          ...payload,
          name: { es: payload.name },
          intro: { es: payload.intro },
          text: { es: payload.text },
        })
      }
    ></BasicForm>
  ) : (
    <Box sx={{ height: '100%', witdh: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
      <CircularProgress></CircularProgress>
    </Box>
  );
};
