import { FormGroupFields } from '../../../models/Form';
import { BasicForm } from '../BasicForm';
import { useBranches } from '../../../hooks/useBranches/useBranches';
import { useExternalBenefits } from '../../../hooks/useExternalBenefits/useExternalBenefits';

const formStructure: FormGroupFields<any> = [
  {
    name: 'name',
    label: 'Nombre',
    required: true,
    type: 'text',
  },
  {
    name: 'description',
    label: 'Descripción',
    type: 'text',
  },
  {
    name: 'category',
    label: 'Categoría',
    type: 'text',
  },
  {
    name: 'location',
    label: 'Ubicación',
    type: 'text',
  },
  {
    name: 'img',
    label: 'Imagen',
    type: 'document',
  },
];

export const CreateOrUpdateExternalBenefits = () => {
  const { get, create, edit: update } = useExternalBenefits();

  return (
    <BasicForm
      formStructure={formStructure}
      defaults={{
        name: '',
        description: '',
        category: '',
        location: '',
      }}
      get={(id) =>
        get(id).then((r) => ({
          ...r.data,
          name: r.data.name.es,
          category: r.data.category.es,
          description: r.data.description.es,
        }))
      }
      create={(payload) =>
        create({
          ...payload,
          name: { es: payload.name },
          category: { es: payload.category },
          description: { es: payload.description },
        })
      }
      update={(id, payload) =>
        update(id, {
          ...payload,
          name: { es: payload.name },
          category: { es: payload.category },
          description: { es: payload.description },
        })
      }
    ></BasicForm>
  );
};
