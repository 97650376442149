import axios from 'axios';

export const create = async ({ id, ...user }: any) => {
  if (!user.email || !user.password) {
    throw { success: false, error: 'Invalid data supplied' };
  }

  const {
    data: { data },
  } = await axios.post('users', user);
  return data;
};
